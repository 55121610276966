.customCont.highlights{
    margin-top: 10%;
    height: 80vh;
    margin-bottom: 70px;
}

.highlights-title{
    display: flex;
    align-items: center;
    justify-content: center;
}