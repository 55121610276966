.section{
    outline-offset: 0px;
    outline: 2px solid rgba(165, 165, 165, .29);
    padding-top: 200px;
    padding-bottom: 160px;
}

.customer-title{
    text-align: center;
    margin-bottom: 60px;
    font-weight: 300;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
}

.customers .nav-pills .customers .nav-link.active {
    background-color: #edb838 !important;
    color: white;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #edb838 !important;
    color: white;
}
.customers .nav-link{
    color: #edb838;
}

.customers .nav-link:focus, .customers .nav-link:hover {
    color: white;
    background-color: #edb838;
}

.pic-section{
    justify-content: center;
    display: flex;
}

.pic-box4{
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: center;
    justify-items: center;
    display: grid;
}

.pic-box3{
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: center;
    justify-items: center;
    display: grid;
}

.image-10 {
    width: auto;
    max-height: 130px;
    max-width: 130px;
}

@media screen and (max-width: 430px) {
    .pic-box4,
    .pic-box3{
        grid-template-columns: 1fr 1fr !important;

    }
}