.using-icon {
    background: #f7db85 !important;
}

.cNXVim {
    margin-top:15px;
}

.rc-card-title{
    color: black !important;
}

.chronobody{
    margin-top: 3rem !important;
}

.rc-card-title{
    text-align: center !important;
}

.rc-card{
    text-align: center !important;

}
.gmYpDn{
    outline: none!important;
}