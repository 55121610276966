.image1 {
    max-width: 65px;
    height: auto;
    padding: 0 15px;
    object-fit: cover;
  }
  
.image1:last-of-type {
    padding-left: 0;
  }

.inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 5rem;
    margin-top: 10px;
    background-color: #fff;
    background-color: #fafafa;
  }
  
.wrapper {
    position: absolute;
    display: flex;
  }
  
section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
  }
  
@keyframes swipe {
    0% {
      transform: translate(0);
    }
  
    100% {
      transform: translate(-100%);
    }
  }
  