.product-body{
    margin-top: 140px;
    margin-bottom: 140px;
    padding-top: 40px;
    padding-bottom: 40px;

}
.prod-descbox{
    padding-left:50px !important;
    text-align: left !important;

}
.prod-img{
    object-fit: contain;
    margin-left: 0;
    margin-right: 20px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-top: 20px;
}
.prod-title{
    text-align: left;
}

.small-p{
    font-size: 1rem !important
}
.prod-p{
    color: #4d4d4d;
    font-size: 25px;
    margin-bottom: 10px;
    font-family: 'Libre Franklin', sans-serif;
    line-height: 24px;
    text-align: left !important;

}

.prod-points{
    color: #6b6b6bd1;
    margin-top: 20px;
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 10px;
    font-family: 'Libre Franklin', sans-serif;
    font-weight:300;
    text-align: left !important;

}

.prod-measure{
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}