.collection-hero{
    background-image: linear-gradient(to right, #f8f6f4 30%, rgba(0, 0, 0, 0)), url(../../assets/contactimg.webp);
    background-position: 0 0, 50% 100%;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    background-attachment: scroll, scroll;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    padding-bottom: 130px;
    display: flex;
    position: relative;
    overflow: hidden;
    margin-bottom: 100px;
}

.collection-title-block{
    z-index: 1;
    width: 50%;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 50%;
    padding-left: 15px;
    padding-right: 5%;
    display: flex;
    position: relative;
}

.collection-title{
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 44px;
    font-weight: 500;
    line-height: 54px;
    margin-top: 20px;
    margin-bottom:10px;
}

.greyBlock{
    background-color: #f8f8f8 !important;
}

.meetTitle{
    padding-top:5rem !important;
}

.topPadding{
    padding-bottom:5rem
}