.navbg {
    z-index: 1000;
    width: 100%;
    height: auto;
    background-color: #fafafa;
    border-bottom: 1px solid rgba(255, 255, 255, .11);
    justify-content: center !important;
    align-items: center !important;
    padding: 0 20px !important;
    display: block !important;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .05);
}

.navContainer {
    z-index: 10;
    max-width: 1170px !important;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    position: relative;
}

.collapse {
    justify-content: flex-end;
}
@media screen and (max-width: 430px) {
    .heading{
        font-size:20px!important;
        line-height: 20px !important;
    }
}
.heading {
    color: #edb838;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Pacifico, sans-serif;
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
}

.nav-flex {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.navItems {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    float: right;
    position: relative;
}

.navItem {
    opacity: 1;
    color: #6c6c6c !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 0;
    margin-right: 0;
    padding: 21px 20px !important;
    font-family: Poppins, sans-serif;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 22px;
}

.navItem:hover {
    color: #edb838 !important;
}

.navbar-nav .nav-link.hover{
    color: #edb838 !important;
}

.dropdown-toggle:hover::after{
    color: #edb838 !important;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: #6c6c6c !important;
}