.customCont {
    z-index: 1;
    width: 1200px !important;
    max-width: 100% !important;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    display: block;
    position: relative;
}

@media screen and (max-width: 430px) {
    .leftHero {
      flex-direction: column-reverse !important;
      text-align:center !important;
    }
    .heroText,
    .heroImg-div {
      width: auto!important;
    }
    .page-title {
        font-size: 20px !important;
        line-height: 37px !important;
    }
    .heroImg {
        width: 400px !important;
        height: 225px !important;
        object-fit: contain !important;
        margin-left:0 !important;

  }
  .caption {
    padding-top: 50px !important;
    margin-bottom: 1rem !important;
    font-size: 14px !important;
    margin-left: 2rem !important;
    }
}
.homeBody {
    margin-top: 80px
}

.homeHero {
    height: 81vh;
    align-items: center;
    margin-bottom: 100px;
    display: flex;
}

.leftHero {
    flex-direction: row;
    align-items: center;
    text-align: left;
    display: flex;
}

.heroText {
    width: 60%;
    margin-right: 13%;
    position: relative;
}

.page-title {
    max-width: 100%;
    color: #000;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-weight: 600;
    line-height: 58px;
    position: relative;
}

.paragraph {
    margin-bottom: 20px;
    margin-right: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 200;
    color: #6c6c6c;
    text-align: left;
    line-height: 26px;
}

.heroImg-div {
    width: 40%;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}

.heroImg {
    width: 800px;
    height: 500px;
    object-fit: contain;
    margin-left: -149px;
}

.heroImg img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

.top-text {
    color: #d77474;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    line-height: 24px;
    margin-bottom: -20px;
    font-size: 18px;
    font-weight: 600;
}

.w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
}

.topPadding {
    margin-top: 40px
}

#quote-button {
    color: #fff;
    background-color: #edb838 !important;
    border-style: none;
    border-color: #000 !important;
    border-radius: 12px;
    margin-right: 0;
    padding: 20px 40px;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    line-height: 22px;
    height: auto;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px #fff !important;
    font-weight: 400;
    transition: all .4s;
}

#quote-button:hover {
    color: #fff;
    background-color: #d19505 !important;
    border-style: none;
    border-color: #53d7db;
    transform: translate(0, -6px);
    opacity: 1;
    border: 0 rgba(0, 0, 0, 0);
}

.groups {
    background-color: #fafafa;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    display: flex;
    position: static;
}

.rotatingcontainer {
    width: 550px;
    overflow: hidden;
}

.customerLink {
    color: rgba(66, 66, 66, .6) !important;
}

h2 {
    font-family: 'Libre Franklin', sans-serif;
    color: rgba(66, 66, 66, .6) !important;
    text-align: center;
    margin-top: 0 !important;
    font-size: 25px !important;
    font-weight: 300 !important;
    text-decoration: underline;
    line-height: 36px !important;

}

.info1 {
    color: rgba(0, 0, 0, 0);
    border-top-style: none;
    padding-top: 0;
    position: relative;
    font-weight: 500;
    border-top: 1px solid #ebebeb;
    padding-bottom: 120px;
}

.titles {
    color: #000;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    font-weight: 600;
    line-height: 50px;
}

.infoH3 {
    text-align: left;
    margin-bottom: 20px !important;
    font-family: 'Poppins', sans-serif;
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 34px !important;
    margin-top: 20px !important;
    color: #000 !important;
}

.p2 {
    margin-bottom: 20px;
    margin-right: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 200;
    color: #6c6c6c;
    text-align: left;
    line-height: 26px;
}

.info1 img {
    max-width: 80%;
    max-height: 80%;
}

.textRow {
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
}

.infoCont {
    z-index: 1;
    width: 1200px !important;
    max-width: 100% !important;
    text-align: left;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    display: block;
    position: relative;
}

.page-title.customPrint {
    margin-top: 100px;
    margin-bottom: 20px;
}

.button-cont {
    justify-content: center;
    display: flex;
    padding: 40px 20px 120px;
}

.print-button {
    width: 240px;
    height: 240px;
    border: 20px solid rgba(0, 0, 0, .05);
    border-radius: 240px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 30px;
    text-decoration: none;
    transition: box-shadow .5s;
    background-color: #f8f6f4;
    border-color: #f5e8c8;
    cursor: pointer;
}

.print-button:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, .1);
}

@media screen and (max-width: 991px) {
    .print-button {
        width: 200px;
        height: 200px;
        margin-left: 0;
        margin-right: 0;
        padding-top: 15px;
    }
    .pl1{
        margin-top:40px;
    }
}


.print-link {
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;
    max-width: 100%;
    display: inline-block;
    color: #000;
    cursor: pointer;
}

.print-img {
    max-width: 90px;
    overflow: clip;
    border: 0;
    vertical-align: middle;
    display: inline-block;
}

.print-text{
    margin-top: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 37px;
}

.getaquote{
    border-style: solid none;
    border-top-width: 2px;
    border-top-color: #dbdbdb;
    border-bottom-width: 2px;
    border-bottom-color: #dbdbdb;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 180px;
    padding-bottom: 180px;
    font-weight: 500;
    position: relative;
}

.quote-title{
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 30px;
    line-height: 46px;
    color: #000;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.quote-p{
    color: #4d4d4d;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Libre-Franklin', sans-serif;
    font-weight: 500 !important;
}

.top-btn-position {
    position: fixed;
    bottom: 15px;
    right: 20px;
  }
  
  .top-btn-style {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid #333;
    background-color: #fff;
    color: #333;
    cursor: pointer;
  }
  
  .top-btn-style:hover {
    animation: none;
    background-color: #333;
    color: #fff;
    border: 2px solid #fff;
  }